@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "proxima nova soft";
        font-weight: 700;
        src: url(/src/fonts/proximasoft-bold-webfont.woff2) format("woff2");
    }
}

/* fix for iOS zoom */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
        background: #eee;
    }
}

body {
    margin: 0;
    font-family: cabin, sans-serif;
    color: #707070;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* should be in component */
.PhoneInputInput {
    border-width: 1px;
    border-color: rgb(209 213 219);
    line-height: 1.25rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    background: white;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 100vh;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 100vh;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-chefstbl-blue;
}

.react-datepicker__input-container {
    height: 100%;
}
