.Container {
    @apply select-none grid rounded-lg bg-blue-100 shadow overflow-hidden;
}

.HeaderContainer {
    @apply z-0 bg-gray-300 max-h-48 relative overflow-hidden;
}

.RatingContainer {
    @apply absolute z-10 left-2 bottom-2 rounded-full bg-white text-chefstbl-blue border border-chefstbl-blue;
}

.PricingContainer {
    @apply absolute z-10 right-2 bottom-2 rounded-full bg-white text-chefstbl-blue border border-chefstbl-blue px-2 py-0.5 grid items-center;
}

.PricingLabel {
    @apply text-xs font-medium;
}

.Image {
    @apply object-cover w-full h-full select-none;
}

.TitleContainer {
    @apply relative bg-gray-500 py-2 px-4 text-white grid grid-cols-listing-content items-center overflow-hidden;
}
.Title {
    @apply text-sm text-ellipsis;
}

.Checkmark {
    @apply rounded-full w-min mx-auto border border-2 border-white transition duration-150 bg-transparent text-chefstbl-light-blue;
}

.CheckmarkChecked {
    @apply bg-white text-chefstbl-blue;
}

.Hidden {
    visibility: hidden;
}

.ErrorContainer {
    @apply px-4 py-2 text-xs bg-red-300 text-black;
}