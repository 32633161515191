.Container {
    @apply flex flex-none basis-12 rounded-t-lg text-white bg-gray-600 select-none;
}

.LimitedWidthContainer {
    @apply sm:max-w-[28rem];
}

.Wrapper {
    @apply grow grid grid-cols-header m-2 text-center place-items-center;
}

.BackButton {
    @apply select-none;
    padding: 5px;
}

.TitleBar {
    @apply flex place-content-center text-sm truncate w-full;
}

.Title {
    @apply truncate;
}

.StarContainer {
    @apply flex inline items-center;
}

.PartialTitle {
    @apply hidden sm:inline;
}

.Link {
    @apply text-center underline;
}
